define('modules/navigation/basic/js/base.js',["app/config","jquery","app/util"], function(config,$,util) {
    return {
        name: 'navigationbasic',
        selectors: false,
        remoteData: [],
        extendable: true,

        lastRoute: null, // Null defaults to home

        routes: [{
            route: '.*',
            fn: function(hash){
                this.navigate(hash);
            },
            priority: 10
        }],

        // TODO - Add our animation options
        options: {
            animationType: false
        },


        initialize: function() {
            if(this.animationType) {
                this.initAnimation();
            }
        },

        initAnimation: function() {
            // TODO - Use our animatins
        },

        navigate: function(hash) {
            hash = this.setDefaultRoute(hash);
            if(hash) {
                var pages = this.getNewPage(hash);
                if (pages) {
                    this.switchPages(pages);
                }
            }
        },

        setDefaultRoute: function(hash) {
            // Set the default starting route
            if(!hash && this.lastRoute === null) {
                return 'home';
            } else if(this.lastRoute == hash) {
                return;
            }
            return hash;
        },

        getNewPage: function(hash) {
            // Get our pages
            var currentPage = $('.pageContainer[data-route="'+this.lastRoute+'"]'),
                newPage = $('.pageContainer[data-route="'+hash.split('/')[0]+'"]');
            if(!newPage || newPage.length === 0 || $(currentPage).is($(newPage))) {
                return false; // TODO - Perhaps implement 404
            } else {
                return [currentPage,newPage];
            }
        },

        switchPages: function(pages) {
            var currentPage = pages[0],
                newPage = pages[1];
            $('.pageContainer').removeClass('active').hide();
            $('.pageContainer .section').removeClass('active');
            $(currentPage).show().css('opacity',1);
            $(newPage).addClass('active').show().css('opacity',1);
            util.scrollBodyToTop();
           
			// TODO this is WIC specific
			if ($('.pageContainer.active').hasClass('homePageContainer')) {
				$('.home-winner-banner').addClass('display-banner').removeClass('no-banner');
				$('#mainNavbar').addClass('homeNav');
			}
			else {
				$('#mainNavbar').removeClass('homeNav');
				$('.home-winner-banner').removeClass('display-banner').addClass('no-banner');
			}
        }
    }
});

define('app/config',['jquery','jquery.livequery'],function($){
    // All the "count" config selectors we will need to monitor
    var countSelectors = [
        '.daysCount',
        '.dayCount',
        '.daysLeft',
        '.dayLeft',
        '.newsCount',
        '.data-newsCount',
        '.dayCountWord'
    ];
    // Default (hardcoded) configuration values
    var defaultConfig = (window.rampitConfig || {
        baseUrl: '/',
        rampitRegTimeLeft: 0,
        rampitRegTimeFrame: 'Register',
        daysLeftSubmit: 0,
        newsCount: 8,
        regConfig: {
            type: "",
            closeTitle: "Registration Closed",
            closeMessage: "Thank you for your interest in this competition, but registration is now closed.",
            email: "",
            fname: "",
            lname: "",
        },
        urls: {
            judges: 'data/judges.json',
            news: 'data/news.json',
            sidebar: 'data/sidebar.json',
            navigationmap: 'data/navigationmap.json',
            dashboardlinks: 'data/dashboardlinks.json',
            competitions: 'data/competitions.json',
            people: 'data/people.json',
            scoring: 'data/scoring.json',
            resources: 'data/resources.json',
            sponsors: 'data/sponsors.json',
            process: '',
            timeline: 'data/timeline.json',
            htmlcontent: 'data/htmlcontent.json',
            events: 'data/events.json',
            problemstatement: 'data/problemstatement.json'
        }
    });
    // Configuration methods, to be applied to the "config" class / require module
    var configMethods = {
        refresh: function() {
            $('.daysCount, .dayCount').text(this.rampitRegTimeLeft);
            $('.dayCountWord').text(this.rampitRegTimeFrame);
            $('.daysLeft, .dayLeft').text(this.daysLeftSubmit);
            $('.newsCount, .data-newsCount').text(this.newsCount);
            // Update navbar text
            if(this.rampitRegTimeLeft < 1 && this.rampitRegTimeFrame.length > 0) {
                $('#mainNavbar a[href*="register"]').html(this.rampitRegTimeFrame).attr('href','#login');
            }
        }
    };
    // Using jquery livequery, watch for changes in specific elements and refrehs config
    $(countSelectors.join(',')).livequery(function(){
        configMethods.refresh();
    });
    // Return and apply the above
    return $.extend({},defaultConfig,configMethods);
});

/*
 * This is the effect that adds a fixed parallax background
 * !!This has not yet been utilized/verified after module refactoring!!
 */
define('modules/animations/parallaxbg/js/base.js',["app/config","jquery","app/util","parallax"], function(config,$,util) {
    return {
        name: 'parallaxbg',
        selectors: false,
        remoteData: [],

        tpl: '<ul class="fixedBg pxBg" data-pxscroll="-0.8" data-pxscrollmode="background"><li class="layer" data-depth="0.2"></li></ul>',

        // Fixed mode - if set to true, one element will be created and the image updated from triggered events
        // If set to false, multiple parallax tpls are immediately created and inserted into each section
        // By setting data attribute for "bgimage" without a string instead as true, a default will be used
        config: {
            fixed: false
        },

        initialize: function() {

            if(this.config.fixed) {
                // Add an event listener for changing parallax images
                $('body').on('parallaxbgupdate', $.proxy(this.updateParallaxImage,this));
                $('body').prepend($(this.tpl)); // Add the parallax container
                if(!util.device.mobile()) {
                    $('.fixedBg').parallax();
                }
            } else {
                setTimeout($.proxy(function() {
                    this.insertParallaxContainers();
                },this),1500); // TODO - Update this use livequery instead of timeout
            }
        },

        updateParallaxImage: function(name) {
            // TODO - Implement this for fixed set to false
        },

        insertParallaxContainers: function() {
            $('*[data-bgimage]').each($.proxy(function(index,el){
                var tpl = $(this.tpl);
                $(tpl).children().first().css('background-image', 'url(img/parallax/' + ($(el).attr('data-bgimage').length > 0 ? $(el).attr('data-bgimage') : 'default') + '.jpg)');
                $(el).prepend(tpl);
                $(tpl).hide();
                setTimeout(function(){
                	$(tpl).fadeIn(350);
                }, 1000);
                
                
                if(!util.device.mobile()) {
                    $(tpl).parallax();
                }
            },this))
        },

        // Legacy Method - not currently used
        // TODO - Implement this as a module option
        randomizeImages: function() {
            // Parallax (mouse)
            // Random the selection for non-home images
            /*$('.pxRand').each(function () {
                var random = Math.ceil(Math.random() * 3);
                $(this).find('.layerRand').each(function (layerIndex, layerEl) {
                    $(layerEl).css('background-image', $(layerEl).css('background-image').replace('parallax_1', 'parallax_' + random));
                });
             });*/
        }
    }
});

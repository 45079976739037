define('app/router',['app/util','jquery','app/config','jquery.livequery','jquery.hashchange'],function(util,$,config){
    return {

        silentBack: false,

        routeHistory: [],
        routeQueue: [],
        originalTitle: null,

        init: function() {
            this.originalTitle = document.title;
            // Hash change listener - this should be the only instance of hashchange listener directly, across the whole app
            $(window).hashchange($.proxy(function(){
                if(!this.silentBack) {
                    this.routeHistory.push(window.location.hash);
                    this.updateTitle(window.location.hash);
                    this.trackAnalytics(window.location.hash);
                }
                this.route(window.location.hash.substr(1)); // Remove the # from this point on
            },this));
        },

        // Called from the initializing app, adds app routes t
        addRoutes: function(routes,component) {
            $.each(routes,function(index,item){
               item.fn = (typeof item.fn == 'string' ? component[item.fn] : item.fn);  // Resolve any string methods
               item.fn = (item.fn ? $.proxy(item.fn,component) : $.noop); // Scope the route method to the component module
            });
            this.routeQueue = this.routeQueue.concat(routes);
        },

        // Main method that handles the routes, and determines how to apply it
        // This method may not always apply the route, see applyRoute below
        route: function(hash) {
            if(this.silentBack) {
                this.silentBack = false;
                return;
            }
            var matchedRoutes = [];
            $.each(this.routeQueue,function(index,item){
                if(RegExp('^'+item.route+'$').test(hash)) {
                    if(!item.priority) {
                        item.priority = 1; // Set a default priority of 1 (highest)
                    }
                    if(item.extra) {
                        item.priority = 1000; // Push extra items to the end of the priority stack
                    }
                    matchedRoutes.push(item);
                }
            });
            if(matchedRoutes.length > 0) {
                matchedRoutes = matchedRoutes.sort(util.dynamicSort('priority'));
                matchedRoutes = matchedRoutes.filter(function(item,index){
                   return (index == 0 || item.extra);
                });
                matchedRoutes.forEach(function(item){
                    this.applyRoute(item,hash);
                }.bind(this));
            } else {
                this.handleMissingRoute(hash);
            }
        },

        // This method is called when a valid route is determined, and actually applies it to browser history
        applyRoute: function(route,hash) {
            if(route && route.fn) {

                // Adds the route delay or defaults it to 50 for sanity purposes
                setTimeout($.proxy(function(){
                    route.fn(hash);
                },route.fn),route.delay || 50);

                if(route.silent) {
                    // TODO - Look more into optimizing this method to handle different nav features better
                    if(this.routeHistory.length < 2) {
                        window.history.replaceState({},'Home','#home');
                        window.history.pushState({},route,'#'+hash);
                        this.silentBack = false;
                    } else {
                        this.silentBack = true;
                    }
                    window.history.back();
                }

                this.handleModal(route); // TODO - Should this be moved inside of route silent?
                
                // TODO better place for this?
            	$('#mainNavbar').removeClass('shrink');
                $('.social-box-dropdown').hide();

            }
        },

        // This method is called on each route apply - and checks for modals
        // If modals are found to be open, and the route is a non-modal - it closes it
        handleModal: function(route) {
            if(!route.modal && !this.silentBack && this.routeHistory.length > 2) {
                if($('.modal').hasClass('in') && $('.modal.in').modal) {
                    $('.modal').modal('hide');
                }
            }
        },

        // TODO - Handle the missing route in a more verbose way? Perhaps explicitly chain to TrackJS
        handleMissingRoute: function(hash) {
            console.warn('Missing route',hash);
        },

        updateTitle: function(hash) {
            var hashTitle = $('div[data-route="'+hash.substr(1).split('/')[0]+'"]').attr('data-title');
            document.title = (hashTitle ? (this.originalTitle + ' - ' + hashTitle) : this.originalTitle);
        },

        trackAnalytics: function(hash) {
            if(hash && window.ga) {
                ga('send', 'pageview', {
                    page: location.pathname+location.search+hash,
                    title: document.title
                });
            }
        }
    }
});

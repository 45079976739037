define('modules/lastcard/combined/js/base.js',["app/config","jquery","app/util","templates"], function(config,$,util,templates) {
    return {
        name: 'lastcard',
        selectors: '.lastCard',
        remoteData: [config.urls.sponsors],

        initialize: function (data) {
            $(this.selectors).append(templates.lastcard({
                sponsors: data
            }));
            
            if (config.rampitRegTimeLeft < 1) {
            	$('.lastCardRegisterBtn').attr('href', '#about').empty().text("Learn More")
            }
            
            $('.social-icon-slider').carousel({
				interval: 5000
			});

			$('.social-icon-slider').carousel('cycle');
			
			$('.socialBox .item').hover(function(){
				$('.social-box-dropdown').fadeIn();
			});
			
			$('.socialBox .lastBoxButton').click(function(){
				$('.social-box-dropdown').fadeIn();
			});
			
			$('.social-box-dropdown').mouseleave(function() {
				$(this).fadeOut();
			});

            // Stop the carousel on main menu nav open
			$('body').on({
                mainnavopen: $.proxy(function() {
                    $(this.selectors).find('.social-icon-slider').carousel('pause');
                },this),
                mainnavclose: $.proxy(function() {
                    $(this.selectors).find('.social-icon-slider').carousel('cycle');
                },this)
            })
			
			config.refresh();
		}
    }
});

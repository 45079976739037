define('modules/process/verticalcard/js/base.js',["app/config","jquery","app/util","templates"], function(config,$,util,templates) {
    return {
        name: 'process',
        selectors: '.processStepWrap',
        remoteData: [],

        data: [{
            id: 1,
            image: 'img/vertical-process-step-1.png',
            title: 'Form a Team',
            content: 'Teams should be community-based, collaborative, and include both public and private partners. A "community" may be defined as a municipality, county, region, or tribal area. ',
            btnText: 'Learn More',
            btnLink: '#about'
        },{
            id: 2,
            image: 'img/vertical-process-step-2.png',
            title: 'Develop a Solution',
            content: 'Solutions can be market-based, technological, or entrepreneurial and must advance the sustainability of the community&#39;s water future.',
            btnText: 'Learn More',
            btnLink: '#application'
        },{
            id: 3,
            image: 'img/vertical-process-step-3.png',
            title: 'Get Feedback',
            content: 'Each completed application will be scored using a common rubric and will receive feedback from judges.',
            btnText: 'Learn More',
            btnLink: '#judges'
        },{
            id: 4,
            image: 'img/vertical-process-step-4.png',
            title: 'Receive Funding',
            content: '$250,000 will be awarded to the team with the most collaborative, impactful, adaptable, and innovative solution.',
            btnText: 'Learn More',
            btnLink: '#scoring'
        }],

        initialize: function (data) {
			this.renderTemplates(data || this.data);
            this.addClickHandlers();
        },

        renderTemplates: function(data) {
            $.each(data,function(i,r){
               data[i].total = data.length;
            });
            $(this.selectors).append(templates.processsteps({
                steps: data.reverse()
            }));
        },

        addClickHandlers: function() {
            $(this.selectors).find('.process-step-next-wrap').on('click',function(e){
                e.preventDefault();
                $(this).parents('.process-step-card').addClass('step-card-out');
                setTimeout($.proxy(function(){
                    $(this).parents('.process-step-card').parent().prepend($(this).parents('.process-step-card'));
                },this),250);
                setTimeout($.proxy(function(){
                    $('.process-step-card.step-card-out').removeClass('step-card-out');
                },this),600);
            });
        }
    }
});

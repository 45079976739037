define('app/user',['app/util','jquery','app/config','jquery.cookie'],function(util,$,config){
    return {

        userStatus: null,

        roles: {
            registered: function() {
                return Number(this.userStatus) >= 1;
            },
            loggedin: function() {
                return Number(this.userStatus) == 2;
            }
        },

        init: function() {
            this.refresh();
        },

        refresh: function() {
            this.userStatus = $.cookie('usr-reg');
        },

        checkRole: function(role) {
            this.refresh();
            return (this.roles[role] && $.proxy(this.roles[role],this)());
        }

    }
});

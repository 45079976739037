define('modules/problemstatement/js/base.js',["app/config","jquery","app/util","templates"], function(config,$,util,templates) {
    return {
        name: 'problemstatement',
        selectors: '.problemStatementWrap',
        remoteData: [config.urls.problemstatement],



        initialize: function (data) {
			this.renderTemplates(data);
			
			$('.problem-statement-slider').carousel({
				interval: 8000
			});

			$('.problem-statement-slider').carousel('cycle');
			//$('.problem-statement-slider').carousel('pause');
			
			util.bootstrapNumberCarousel('.problem-statement-slider');
			
			
			//$('.expand-question').hover(function(){
			//	$('.problem-statement-slider').carousel('pause');
			//	$(this).parents('.problem-slide-item').find('.slide-caption').addClass('showCaption');
		  	//}, function () {
			//	$('.problem-statement-slider').carousel('cycle');
			//	$(this).parents('.problem-slide-item').find('.slide-caption').removeClass('showCaption');
		  	//});
		  	$('.expand-question').click(function(){
				$('.problem-statement-slider').carousel('pause');
				$('.expand-question').hide();
				$('.expand-question.close').show();
				$(this).parents('.problem-statement-slider').find('.caro-nav-container').hide();
				$(this).parents('.problem-slide-item').find('.slide-image').hide();
				$(this).parents('.problem-slide-item').find('.slide-caption').addClass('showCaption');
		  	});
		  	$('.expand-question.close').click(function(){
				$('.problem-statement-slider').carousel('cycle');
				$('.expand-question').show();
				$('.expand-question.close').hide();
				$(this).parents('.problem-statement-slider').find('.caro-nav-container').show();
				$(this).parents('.problem-slide-item').find('.slide-image').show();
				$(this).parents('.problem-slide-item').find('.slide-caption').removeClass('showCaption');
		  	});
  					
        },

        renderTemplates: function(data) {
            $.each(function(i,r){
               data[i].total = data.length;
            });
            $(this.selectors).append(templates.problemstatement({
                slideitem: data
            }));
        }

        /*addHoverHandlers: function() {
            $(this.selectors).find('.expand-question').on('hover',function(e){
                //e.preventDefault();
                $(this).parents('.problem-slide-item').find('slide-caption').show();
            });
        }*/
        

    }
});

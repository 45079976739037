define('modules/navbar/compact/js/base.js',["app/config","jquery","app/util","templates","waypoints","jquery.livequery"], function(config,$,util,templates) {
    return {
        name: 'navbar',
        selectors: '.navbarWrap',
        remoteData: [],

        config: {
            hiddenNavBar: false
        },

        initialize: function () {

            // Apply config
            if($(this.selectors).attr('data-hiddennav')) {
                this.config.hiddenNavBar = true;
            }

            // Render the template
            $('.navbarWrap').append(templates.navbar());

            // Handling for activePageTitle text swaps
            $('.navbarWrap .activePageTitle:visible').livequery(function() {
                $(this).text(util.getActivePageTitle());
            });

            // Set navbar animations depending on layout type / animation mode
            // TODO - Streamline this process across all navbar modules
            if(this.config && this.config.hiddenNavBar) {
                $(window).scroll($.proxy(function (event) {
                    var scrollTop = $(window).scrollTop();
                    if (scrollTop > this.lastScrollTop && scrollTop > 0) {
                        $('.navbarWrap').removeClass('slideDownIn').addClass('slideUpOut');
                        $('.navbar-brand').removeClass('nb-slidedown').addClass('nb-slideup')
                        
                    } else {
                        $('.navbarWrap').removeClass('slideUpOut').addClass('slideDownIn');
                        $('.navbar-brand').removeClass('nb-slidedown').addClass('nb-slideup')
                    }
                    if (scrollTop == 0) {
                    	$('.navbar-brand').removeClass('nb-slideup').addClass('nb-slidedown');
                    }
                    this.lastScrollTop = scrollTop;
                }, this));
            } else {
                // Automatic detection of animation mode for navbar
                if($(window).width() < 768) {
                    setTimeout($.proxy(this.animationMode.basic,this),1000);
                } else {
                    this.animationMode.cardpanel();
                }
            }
        },

        animationMode: {
            // TODO - Figure out not to constrain this context to pageContainer, as that relies on using a specific nav layout
            basic: function() {
                $('.pageContainer .section:first-of-type').waypoint(function (direction) {
                    $('#mainNavbar').toggleClass('shrink', (direction == 'down'));
                }, {
                    offset: -100,
                    context: '.pageContainer'
                });
                $('.pageContainer .section:last-of-type').waypoint(function (direction) {
                    $('#mainNavbar').toggleClass('shrink', (direction == 'up'));
                }, {
                    offset: 100,
                    context: '.pageContainer'
                });
            },
            cardpanel: function() {
                // Navbar shrink state on panel snap events
                $('body').on('cardsnapfinish',function(event,panel){
                    if ($(panel).parents('.pageContainer').hasClass('enableNavAni') || util.device.mobile()) {
                        $('#mainNavbar')[($(panel).index() == 0 || $(panel).index()+1 == $(panel).parent().children().length) ? 'removeClass' : 'addClass']('shrink');
                    }
                });
            }
        }
    }
});

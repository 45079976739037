define('modules/timeline/js/base.js',["app/config","jquery","app/util","templates","bootstrap/tooltip","bootstrap/carousel"], function(config,$,util,templates) {
    return {
        name: 'timeline',
        selectors: '.timelineSection',
        remoteData: [(config.urls.timeline)],

        routes: [{
            route: 'timeline',
            fn: function() {
                this.firstPoint();
                //setTimeout(function () {
                //    //$('.timelinePoint.first').trigger('click');
                //}, 500);
            },
            extra: true,
            delay: 2300
        }],

        initialize: function(data) {
        	

            // Create timestamps for each record
            $(data).each(function(index,record){
                record.timestamp = new Date(record.date).getTime();
                data[index] = record;
            });
            // Sort by timestamps
            data = data.sort(util.dynamicSort('timestamp','id'));

            // Bounding
            var startMonth = new Date(new Date(data[0].timestamp).getFullYear(), new Date(data[0].timestamp).getMonth(), 1, 0, 0, 0),
                endMonth = new Date(new Date(data[data.length - 1].timestamp).getFullYear(), new Date(data[data.length - 1].timestamp).getMonth() + 1, 0, 0, 0, 0),
                startExtra = data[0].timestamp - startMonth.getTime(),
                endExtra = endMonth.getTime() - data[data.length - 1].timestamp;


            //var msCount = (data[data.length-1].timestamp-data[0].timestamp),
            //Let the range be the full period
            var msCount = (endMonth.getTime() - startMonth.getTime()),
                msIncrement = 100/msCount;
                
            // Render the template
            $('.timelineSection').append($(templates.timeline({
                timelinePoints: data,
                msIncrement: msIncrement,
                start: data[0].timestamp
            })));

            // Check the positioning of all points and push them apart to ensure no overlap
            /*$('.timeline .timelinePoint').each(function(){ // TODO - Finish timeline point overlap
            });*/

            // Give the timeline gutters
            if($(window).width() > 1024) {
            	$('.timeline').css({
                //width: (100 - ((startExtra + endExtra) * msIncrement)) + '%',
                	width: '100%',
                	'margin-left': (startExtra*msIncrement)+'%'
            	});
            }


            // Delay between carousel cycles, if automated
            var carouselAutoDelay = 5000;

            // if the browser height is less than 640px and still desktop
            if (($(window).height() < 640) && ($(window).width() > 768)) {
                //$('.timeline-bottom').css('position', 'absolute');
                //$('.timeline-bottom').css('top', ( $('.timelineInfo').height() + 150) + 'px');
            }

            // Special handling for smaller screens
            if ( $(window).width() <= 768 ) {

                // Adjust the timeline's position
                //$('.timeline-bottom').css('position', 'absolute');
                //$('.timeline-bottom').css('top', ( $('.timelineInfo').height() + 80) + 'px');

                // Force the carousel to cycle
                $('#timelineInfo').carousel({
                    interval: carouselAutoDelay
                });

            }
            
        

            //===== Beginning of carousel slide animation =====//
            $('#timelineInfo').on('slide.bs.carousel', function(event) {

                $('.timeline .timelinePoint').removeClass('active');
                $('.timeline .timelinePoint').eq($(event.relatedTarget).index()).addClass('active');

                // Update the current Period information
                $('.timeline-period h3').hide().html( $(event.relatedTarget).data('period') ).fadeIn(550);
                $('.timeline-period h6').hide().html( $(event.relatedTarget).data('period-length') ).fadeIn(550);
                $('.timeline .connectLine, .timeline-calendar li').removeClass('highlight').filter('[data-period*="'+$(event.relatedTarget).data('period')+'"]').addClass('highlight');

                // Clear other tooltips and show current slide
                $('.timeline .timelinePoint').tooltip('hide');
                $('.timeline .timelinePoint').eq($(event.relatedTarget).index()).attr('data-trigger','manual').tooltip('show');

                // Mobile timeline scrolling
                if($(window).width() <= 768) {
                    var leftPosition = $('.timeline .timelinePoint').eq($(event.relatedTarget).index()).css('left');
                    $('.timeline, .timeline-calendar').css('position','relative');
                    $('.timeline, .timeline-calendar').animate({
                        left: (-parseFloat(leftPosition) * .8)
                    });

                    // Adjust timeline position on mobile screens
                    setTimeout( function() {
                        //$('.timeline-bottom').css('top', ( $('.timelineInfo').height() + 80) + 'px');
                    }, 600);
                }

            }).swiperight(function() {
                $(this).carousel('prev');
            }).swipeleft(function() {
                $(this).carousel('next');
            });


            //===== After carousel slide animation =====//
            $('#timelineInfo').on('slid.bs.carousel', function(event) {

                // Are we on mobile?
                if ( $(window).width() <= 768 ) {


                    var totalCarouselItems = $('#timelineInfo .item').length,
                        currentCarouselItem = $('#timelineInfo .item.active').index() + 1;

                    // Are we on the last slide?
                    if ( totalCarouselItems == currentCarouselItem ) {

                        // Stop the carousel
                        $('#timelineInfo').carousel('pause');

                        // Wait a moment, then return to the first slide
                        setTimeout(function() {
                        
                            $('#timelineInfo').carousel(0);
                            setTimeout(function(){
								// Show the very first tooltip
								$('.timelinePoint.first').tooltip('show');
							}, 1000);
                            
                        }, carouselAutoDelay);

                    }

                };


            });


            // Init tooltips
            $('.timelinePoint').tooltip();

            // Add the indiciator for today's date and show the tooltip
            var nowPosition = (new Date().getTime()-data[0].timestamp)*msIncrement;
            $('<div></div>').addClass('timelineNow').attr('data-title','Today').css('left',nowPosition+'%').appendTo($('.timeline'));
            $('.timelineNow').tooltip({
                template: '<div class="tooltip tooltipNow"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'
            });

            // Tooltip listeners
            $('.timelinePoint, .timelineNow').on('show.bs.tooltip',function(){
                if(!$(this).hasClass('active')) {
                    $('.timelinePoint.active').tooltip('hide');
                }
            }).on('hide.bs.tooltip',function(){
                if($(this).hasClass('active')) {
                    //console.log($('.timelinePoint+.tooltip.in').length)
                    //return false; // Do not allow closing on currently viewed point
                } else {
                    // Otherwise ensure the active point is reshown
                    $('.timelinePoint.active').tooltip('show');
                }
            });

            // Init timeline point clickers
            $('.timelinePoint').click(function(e){
                e.preventDefault();
                $('#timelineInfo').carousel($('.timelinePoint').index($(this)));
            });

            // Update the init Period information
            $('.timeline-period h3').html( data[0].period );
            $('.timeline-period h6').html( data[0].periodlength );
            $('.timeline .connectLine[data-period="'+data[0].period+'"]').addClass('highlight');

            //===== Init Calendar =====//

            var firstMonth  = new Date(data[0].timestamp),
                lastMonth       = new Date(data[data.length - 1].timestamp),
            // Pointer to the DOM
                calendar        = $('.timeline-calendar ul'),
            // Array of month name strings
                monthNames      = ['January','February','March','April','May','June','July','August','September','October','November','December'],
            // Counts the number of months between our two dates
                monthCount      = 0;

            // Fetch the first date in the timestamp
            var curMonth = firstMonth;

            // Adjust our last month to offset for final month
            //lastMonth.setMonth(lastMonth.getMonth() + 1);

            // For each month...
            while ( curMonth < endMonth ) {

                // Fetch the month's name
                var curMonthName = monthNames[curMonth.getMonth()];

                // Get any timeline points and periods that are inside this month
                var periods = $.map(data,function(v){
                    var month = new Date(v.timestamp).getMonth();
                    if(month == curMonth.getMonth()) {
                        return v.period;
                    } else {
                        return null;
                    }
                });

                // Append the month to our calendar
                calendar.append('<li data-period="'+periods.join(',')+'">' + curMonthName + '</li>');

                // Increase the current month by 1
                curMonth.setMonth( curMonth.getMonth() + 1 );
                // Increase our month counter
                monthCount++;
            }

            // Finally, adjust the width of our month names evenly
            var borderWidth = $('.timeline-calendar li').css('border-right-width').replace("px", "");

            $('.timeline-calendar li').width( (100 / monthCount) - (borderWidth) + '%' );

            // timeout fixes the bug of the first tool tip
            // showing up in the wrong place
            
            
            if (($('.timeline').css('margin-left') <= '1%' ) && ($('.timelineContainer .timeline-bottom').css('width') == '100%' )) {
            	$('.timelinePoint.first').css('left','1%');
            }
			
			this.cascadeTimeline();
        },
        
        cascadeTimeline: function() {
        
        	var detailTop = parseInt($('.timeline-bottom .timelinePoint.first').css('top'));
        	var w = $(window).width();
        	
        	
        	var resizeTimer;

			$(window).on('resize', function(e) {
				w = $(window).width();
			
				$('.timeline-bottom .tooltip').fadeOut();
				$('.timelinePoint').tooltip('enable');
				$('.timeline-bottom .tooltip').tooltip('hide');

				clearTimeout(resizeTimer);
				resizeTimer = setTimeout(function() {

			
					if ($('.timeline-bottom').hasClass('overview')) {
						$('.timeline-toggle .overview').trigger('click');
					}
					else {
						$('.timeline-toggle .detail').trigger('click');
					}
		
			  }, 500);

			});
        	
        	
        	$('.timeline-toggle .detail').click(function(){
        		$(this).css('opacity', 1);
        		$('.timeline-toggle .overview').css('opacity', 0.5);
        		
        		$('.timeline-bottom.overview .tooltip').unbind('click');
        		$('.timelineInfo').fadeIn();
        		$('.timelineNav').fadeIn();
        		$('.timeline-bottom').removeClass('overview');
        		$('.timeline-bottom').animate({bottom: ''});
        		
        		$('.timeline-bottom .tooltip').each(function(){
        		
        			$(this).css({ top: detailTop, bottom: '' }).removeClass('overview');
        			$(this).find('.tooltip-arrow').css({height: '', bottom: ''});
        			
        			$(this).hide().css('opacity','');
        		
        		});
        		
        		//$('.timeline-bottom .timelinePoint.first').tooltip('show');
        		
        		// put back active class
        		var activeItem = $('.carousel-inner.timelineInfo div.item.active').index();
        		$('.timelinePoint').eq(activeItem).addClass('active');
        		
        		$('.timelinePoint').tooltip('enable');
        		$('.timelinePoint.active').tooltip('show');
        		
        		
        		
        		
        	});
        	
        	$('.timeline-toggle .overview').click(function(){
        		$(this).css('opacity', 1);
        		$('.timeline-toggle .detail').css('opacity', 0.5);
        	
        		$('.timelineInfo').fadeOut();
        		$('.timelineNav').fadeOut();
        		$('.timeline-bottom').animate({bottom: '60px'}).addClass('overview');
        		
        		//$('.timelinePoint').unbind('mouseenter mouseleave');

        		
        		$('.timeline-bottom .timelinePoint').tooltip('show');
        		$('.timeline-bottom .timelinePoint.first').tooltip('show');
        		
        		$('.timeline-bottom .timelinePoint').each(function(){
        			$(this).removeClass('active');
        		});
        		
        		var startBtm = parseInt($('.timeline-bottom .tooltip:first').css('bottom'));
        		
        		$('.timeline-bottom .tooltip').each(function(){
        		
        			$(this).show().css('opacity',1).addClass('overview');
        		
					var startTop = parseInt($(this).css('top'));
					var newTop = startTop;
					var lineHeight = Math.abs(newTop);
        			
        			var curW = $(this).width();
        			var curL = $(this).offset().left;
        			var curBtm = parseInt($(this).css('bottom'));
        			//var bubbleHeight = parseInt(($(this).height()) + 20);
        			var bubbleHeight;
        			
        			if ($(this).nextAll('.tooltip').length) {
        				
        				var nextBubble = $(this).nextAll('.tooltip');
        				bubbleHeight = parseInt(($(nextBubble).height()) + 20);
        				
        				var nextW = nextBubble.width();
        				var nextL = nextBubble.offset().left;
        				
        				//if overlap
        				if(((w >= 900) && (nextL < (curL + curW))) && (curBtm < 150)) {
        				
        					nextBubble.css({ top: (newTop - bubbleHeight), bottom: '' });
							newTop = newTop - bubbleHeight;
						
							lineHeight = Math.abs(newTop);
						
							nextBubble.find('.tooltip-arrow').css({height: (lineHeight -  bubbleHeight) + 45, bottom: -Math.abs((lineHeight -  bubbleHeight) + 45)});
						
        				}
        				else if (((w < 899) && (nextL < (curL + curW))) && (curBtm < 220)) {
        					nextBubble.css({ top: (newTop - bubbleHeight), bottom: '' });
							newTop = newTop - bubbleHeight;
						
							lineHeight = Math.abs(newTop);
						
							nextBubble.find('.tooltip-arrow').css({height: (lineHeight -  bubbleHeight) + 45, bottom: -Math.abs((lineHeight -  bubbleHeight) + 45)});
						
        				}
        				else {
        					newTop = startTop;
        					lineHeight = Math.abs(newTop);
        		
        					//nextBubble.animate({ top: (newTop) });
        					nextBubble.css({ top: '', bottom: (startBtm) });
        					nextBubble.find('.tooltip-arrow').css({height: '', bottom: ''});
        				}
        			}
        			else {
        			}
        		});
        		
        		$('.timelinePoint').tooltip('disable');
        		
        		
        		// Init timeline point clickers
				$('.timeline-bottom.overview .tooltip').click(function(e){
					
					e.preventDefault();
					
					var ttIndex = parseInt($(this).index('.overview'));
					$('.timelinePoint').eq(ttIndex - 1).trigger('click');
					
				});
        		
        		
        	});
        },

        firstPoint: function() {
        	setTimeout(function(){
            	// Show the very first tooltip
            	$('.timelinePoint.first').tooltip('show');
            }, 1000);
        }
    }
});

define('modules/social/js/base.js',["app/config","jquery","app/util"], function(config,$,util) {
    return {
        name: 'social',
        selectors: false,
        remoteData: [],

        initialize: function () {
            var clickFn = this.onSocialClick;
            $('.shareSocial').livequery(function(){
                $(this).on('click',clickFn);
            },function(){
                $(this).off('click',clickFn);
            });
        },

        // This method is scoped to the element itself, not the module class
        onSocialClick: function(e){
            e.preventDefault();
            e.stopPropagation();

            var loc = location.href,
                action = $(this).attr('data-action');

            if (action == 'twitter') {
                var title = $(this).attr('title') || document.title;
                window.open('http://twitter.com/share?url=' + loc + '&text=' + title, 'twitterwindow', 'height=500, width=550, top=' + (100) + ', left=' + ($(window).width() / 2 + 275) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
            } else if (action == 'facebook') {
                var t = document.title;
                window.open('http://www.facebook.com/sharer.php?u=' + encodeURIComponent(loc) + '&t=' + encodeURIComponent(t), 'sharer', 'status=0,width=626,height=436, top=' + (100) + ', left=' + ($(window).width() / 2 + 313) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
            } else if (action == 'youtube') {
                var title = $(this).attr('title') || document.title;
                window.location.assign("https://www.youtube.com/watch?v=" + title);
            } else if (action == 'google') {
                window.open('https://plus.google.com/share?url=' + encodeURIComponent(loc), 'Google Share', 'status=0,width=626,height=436, top=' + ($(window).height() / 2 - 225) + ', left=' + ($(window).width() / 2 - 313) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
            }
        }
    }
});

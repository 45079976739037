define('modules/footer/compact/js/base.js',["app/config","jquery","app/util","templates","waypoints"], function(config,$,util,templates) {
    return {
        name: 'footer',
        selectors: '.footerWrap',
        remoteData: [],

        initialize: function () {
            // Render the template
            $('.footerWrap').append(templates.footer());
        }
    }
});

define('modules/judges/verticalgrid/js/base.js',["app/config","app/util","jquery","templates","waypoints","parallax"], function(config,util,$,templates,Waypoint) {
    return {
        name: 'judges',
        selectors: '.judgePageWrap, .judgeSection',
        remoteData: [config.urls.judges],

        routes: [{
            route: 'judges',
            extra: true,
            delay: 1000,
            fn: 'affixSidebars'
        }],

        waypoints: [],
        navWaypoints: [],

        mobile: false,

        initialize: function(data) {
            this.renderTemplates(data);
            this.addClickHandlers(data);
            $(window).smartresize($.proxy(this.resizeLayout,this));
        },

        renderTemplates: function(data) {
            var judgeGroups = [],
                templateData = {
                    judgeGroups: []
                };
            $.each(data,function(index,record) {
                if (!judgeGroups[record.type]) {
                    judgeGroups[record.type] = {
                        name: record.type,
                        cms: record.cms,
                        desc: record.desc,
                        descTitle: record.descTitle,
                        records: []
                    };
                }
                record['firstName'] = record['name'].split(' ')[0];
                record['lastName'] = record['name'].split(' ').length > 1 ? record['name'].split(' ')[1] : '';
                judgeGroups[record.type].records.push(record);
            });
            for(var key in judgeGroups) {
                templateData.judgeGroups.push(judgeGroups[key]);
            }
            $('.judgePageWrap').append(templates.judgespage(templateData));

        },

        addClickHandlers: function(data) {
            this.handleJudgeBoxClick(data);
            $(this.selectors).find('.judgesNav').livequery($.proxy(function(){
                this.handleJudgesNavClick();
            },this),function(){
                // TODO - Implement off handling
            });
            $('.meetJudgesBtn').on('click',function(e){
                e.preventDefault();
				//setTimeout($.proxy(function() {
				//	$(this).parents('.judgeSection').find('.judgebox').first().trigger('click');
				//	var jdi = $(this).parents('.judgeSection').find('.judgesDetailInner');
                //	util.scrollElToTop(jdi, -50);
				//},this), 500);
				$(this).parents('.judgeSection').find('.judgebox').first().trigger('click');
				var jdi = $(this).parents('.judgeSection').find('.judgesDetailInner');
				util.scrollElToTop(jdi, -50);
			
                
                
            });
        },

        handleJudgeBoxClick: function(data) {
            var module = this;
            $(this.selectors).find('.judgebox').on('click',function(e){
                e.preventDefault();
                var judgeId = $(this).attr('data-judge'),
                    judgeData = $.grep(data,function(record){
                        return (record.id == judgeId);
                    });
                if(judgeData && judgeData.length > 0) {
                    judgeData = judgeData[0];
                } else {
                    return;
                }
                if(module.mobile) {
                    var mobileJudgeData = $.grep(data,function(record){
                        return (judgeData.type == record.type);
                    });
                    judgeData = mobileJudgeData;
                }
                var judgesWrapEl = $(this).parents('.judgesWrap'),
                    judgesDetailInnerEl = $(judgesWrapEl).find('.judgesDetailInner');
                $('.judgePageWrap .judgebox, .judgePageWrap .judgesDetailInner').removeClass('active');
                $(this).addClass('active');
                // Reset sidebars
                $('.judgePageWrap .judgesDetailInner').addClass('inactive');
                $(judgesDetailInnerEl).addClass('active').removeClass('inactive');
                $('.judgePageWrap .judgesDetailInner').find('.judgeinfoWrap, .judgesNav').remove();
                $('.judgePageWrap .judgesDetailInner').find('.judgeGroupInfo').hide();
                $('.judgePageWrap .judgesDetailInner').not(judgesDetailInnerEl).find('.judgeGroupInfo').show();
                // Update template
                if(module.mobile) {
                    $.each(judgeData,function(){
                        $(judgesDetailInnerEl).append(templates.judgeinfo(this));
                        $(judgesDetailInnerEl).find('.judgesNav').not($(judgesDetailInnerEl).find('.judgesNav').first()).remove();
                        $(judgesDetailInnerEl).find('.judgeinfoWrap').removeClass('stopOver inner-scrollable');
                    });
                    module.enableNavWaypoints();
                    // Scroll into position
                    setTimeout($.proxy(function() {
						util.scrollElToTop($(judgesDetailInnerEl).find('.judgeinfoWrap[data-judge="'+judgeId+'"]'),'-5%');
					},this), 500);
                } else {
                    $(judgesDetailInnerEl).append(templates.judgeinfo(judgeData));
                    // Scroll into position
                    util.scrollElToTop($(this), -(($(window).height() / 2) - ($(this).height() / 2)), 750);
                    $(judgesDetailInnerEl).scrollTop(0); // Reset inner scroll as well
                    // Move the sidebar up if necessary
                    setTimeout(function () {
                        if ($(judgesDetailInnerEl).css('position') != 'fixed') {
                            $(judgesDetailInnerEl).css('top', -($(judgesDetailInnerEl).parents('.judgeSection').offset().top - $(window).scrollTop() - (($(window).height() * 0.25) / 2)));
                        }
                    }, 750); // Duration from above scroll
                }
                // Update navigation
                $(judgesDetailInnerEl).find('.judgeCounter .totalCount').html($(this).parents('.judgesWrap').find('.judgebox').length);
                $(judgesDetailInnerEl).find('.judgeCounter .currentCount').html($(this).index()+1);
            });
        },

        handleJudgesNavClick: function() {
            var module = this;
            // Set the next/previous and close handlers
            $('.judgesNav .prev').click(function(e){
                $(this).parents('.judgeSection').find('.judgebox.active').prev().trigger('click');
                e.preventDefault();
            });
            $('.judgesNav .next').click(function(e){
                $(this).parents('.judgeSection').find('.judgebox.active').next().trigger('click');
                e.preventDefault();
            });
            $('.judgesNav .closeNav').click(function(e){
                e.preventDefault();
                $(this).parents('.judgeSection').find('.judgebox').removeClass('active');
                $(this).parents('.judgePageWrap').find('.judgesDetailInner').removeClass('inactive active');
                $(this).parents('.judgeSection').find('.judgeGroupInfo').show();
                $(this).parents('.judgeSection').find('.judgeinfoWrap, .judgesNav').remove();
                setTimeout($.proxy(function() {
					util.scrollElToTop($('body'),0);
				},this), 500);
                module.disableNavWaypoints();
            });
            // Update the judge group toggle text and handler
            // TODO - Move this to it's own method
            var nextGroup = $('.judgesNav').parents('.judgeSection').nextAll('.judgeSection').first();
            if(nextGroup.length == 0) {
                nextGroup = $('.judgesNav').parents('.judgeSection').prevAll('.judgeSection').last();
            }
            if(nextGroup.length > 0) {
                var groupName = $(nextGroup).attr('data-title');
                if ($(window).width() < 768) {
                    groupName = groupName.split(' ').map(function (a) {
                        return a.substr(0, 1);
                    }).join(' ');
                }
                $('.judgeGroupToggle').html('<i class="hidden-xs">Meet the </i>' + groupName);
                $('.judgeGroupToggle').click(function (e) {
                    $(this).parents('.judgesNav').find('.closeNav').trigger('click');
                    e.preventDefault();
                    $(nextGroup).find('.judgebox').first().trigger('click');
                    util.scrollElToTop($(nextGroup).find('.judgesDetail'), -150);
                });
            }
        },

        affixSidebars: function() {
            // Clear existing waypoints
            $.each(this.waypoints,function(){
               this.destroy();
            });
            var waypoints = [];
            // Create and save waypoints
            $('.judgeSection').each(function(){
                waypoints = waypoints.concat($(this).waypoint(function(direction){
                    var sideBar = $(this.element).find('.judgesDetailInner');
                    $(sideBar).css({
                        'position': (direction == 'down' ? 'absolute' : 'fixed'),
                        'top': (direction == 'down' ? 'auto' : '0px')
                    });
                    if(!$(sideBar).hasClass('leftSide')) {
                        $(sideBar).css('bottom',(direction == 'down' ? '-250px' : 'auto'));
                    } else {
                        $(sideBar).css('bottom',(direction == 'down' ? '0px' : 'auto'));
                    }
                },{
                    offset: function(waypoint) {
                        if($(this.element).find('.judgesDetailInner').hasClass('leftSide')) {
                            //return -($(this.element).outerHeight()*0.75 - 350);
                            return -100; // TODO - We need to probably handle this dynamically
                        } else {
                            return -($(this.element).outerHeight()*0.75 - 150);
                        }
                    },
                    group: 'judges'
                }));
                waypoints = waypoints.concat($(this).find('.judgesDetailInner').waypoint(function(direction){
                    $(this.element).css({
                        'position': (direction == 'down' ? 'fixed' : 'absolute'),
                        top: (direction == 'down' ? '0px' : ($(this.element).hasClass('leftSide') ? '-100px' : '100px'))
                    });
                },{
                    offset: function() {
                        return 0;
                    },
                    group: 'judges'
                }));
            });
            this.waypoints = waypoints;
            this.resizeLayout();
        },

        resizeLayout: function() {
            $('.judgesDetailInner').innerWidth($('.judgesDetail').width());
            $.each(this.waypoints,function(){
                this[$(window).width() > 1023 ? 'enable' : 'disable']();
            });
            this.mobile = !($(window).width() > 1023);
            //we don't need the stopOver class anymore on .judgeGroupInfo
            //$(this.selectors).find('.judgeGroupInfo').toggleClass('stopOver',($(window).width() > 1023));
            window.Waypoint.refreshAll();
        },

        enableNavWaypoints: function() {
            $('.judgesDetailInner.active .judgeinfoWrap').each($.proxy(function(index,element){
                var inview = new window.Waypoint({
                    element: $(element),
                    offset: 50,
                    continuous: false,
                    handler: $.proxy(function(direction) {
                        $('.judgebox').removeClass('active');
                        $('.judgebox[data-judge="'+$(element).attr('data-judge')+'"]').addClass('active');
                        $('.judgesNav .currentCount').html($(element).index() == 1 ? 1 : $(element).index()-1);
                    },this)
                });
                this.navWaypoints.push(inview);
            },this));
            window.Waypoint.refreshAll();
        },

        disableNavWaypoints: function() {
            $.each(this.navWaypoints,function(index,item){
                item.destroy();
            });
            this.navWaypoints = [];
        }
    }
});

define('modules/dashboardmenu/js/base.js',["app/config","jquery","app/util","templates","app/forms","jquery.cookie","bootstrap/popover"], function(config,$,util,templates,forms) {
    return {
        name: 'mainmenu',
        selectors: '.mainMenuWrap',
        remoteData: [],

        hoverMenuEnabled: false, // TODO - This will eventually hook into the below method when enabled

        config: {
            // TODO - Set up the proper or combined modal mode
            // TODO - Set up the data-attr apply config method
            mode: 'sidebaronly', // Two modes - full or sidebaronly
            pushPageContent: 'left' // Opening the menu pushes the page content to the left/right, defaults to false
        },

        routes: [{
            route: '.*',
            fn: function(hash){
                this.updateToggleMenu(false);
            },
            extra: true,
            delay: 150
        },{
            route: 'menu',
            fn: 'routeDashboardLinks',
            extra: true
        },{
            route: 'register',
            fn: 'routeRegister',
            extra: true
        },{
            route: 'login',
            fn: 'routeLogin',
            extra: true
        },{
            route: 'forgot',
            fn: 'routeForgot',
            extra: true
        }],

        initialize: function (data) {

            // Render the template
            $('.mainMenuWrap').append(templates.dashboardmenu());

            if(this.config.mode == 'sidebaronly') {
                $('.mainMenuWrap').find('#actionMenu, #centerMenuContent').remove();
            }

            var mainMenu = $('#mainMenu');

            // Only applies if the mainMenu is present
            if(mainMenu.length > 0) {

                // Read user cookie to set the default side content
                var userStatus = $.cookie('usr-reg');

                if(userStatus == 2) {
                    this.updateToggleMenu(templates.dashboardlinks());
                    $('#actionMenu').toggleClass('dashboardLinksShown',true);
                    // If the user is logged in, update the days left to submit and logout link
                    $('#mainNavbar a[href*="login"]').text('Logout').attr('href','/logout.aspx');
                    if(config.daysLeftSubmit >= 0) {
                        $('#mainNavbar a[href*="register"]').html('<span class="dayLeft"></span> Days to Submit').attr('href','#');
                    }
                } else if(userStatus == 1) {
                    this.updateToggleMenu(templates.login(config));
                    $('#actionMenu').toggleClass('loginShown',true);
                } else {
                    this.updateToggleMenu(templates.register(config));
                    $('#actionMenu').toggleClass('registerShown',true);
                }

                // Detect and handle close button
                $('.dashboardToggle, .closeWrap').on('click',$.proxy(function(e){
                    this.updateToggleMenu(!$('#mainMenu').is(':visible'),false);
                    return false;
                },this));

                $('#actionMenu .mobBack').click(function(e){
                    e.preventDefault();
                    $('#sideMenu').removeClass('mobAway');
                });

                $('#sideMenu .mobBack').click(function(e){
                    e.preventDefault();
                    $('#sideMenu').addClass('mobAway');
                });
            }

            // Handle the hash routing for the main menu
            // TODO - Important - we need to reimplement this into routing methods and also handle situations where both modals and navmenu are being used
            if(this.config.mode == 'full') {
                $(window).hashchange($.proxy(function () {
                    if (window.bRampit) {
                        rampitInit(location.hash.substr(1));
                    }
                }, this));
            }

            // Navbar flyout
            $('.partnerLink, .partnerFlyout .closeBtn').click(function(e){
                e.preventDefault();
                $('.partnerFlyout').toggleClass('masked');
                setTimeout(function(){
                    $('.partnerFlyout').toggleClass('open');
                    $('.introPage').toggleClass('bumpLeft');
                },50);
            });

            $('.flyoutTrigger, .rightFlyout .closeBtn, .leftFlyout .closeBtn').click(function(e){
                e.preventDefault();
                var flyoutEl = $(this).attr('data-flyout') || $(this).parents('.rightFlyout, .leftFlyout');
                $(flyoutEl).toggleClass('masked');
                setTimeout(function(){
                    $(flyoutEl).toggleClass('open');
                    $('.introPage').toggleClass('bumpRight');
                },50);
            });

            // Add tooltip dropdown support about button
            /*var popoverHtml = $('<ul class="aboutPopoverList"><li><a href="about.html">About</a></li><li><a href="#">About Two</a></li></ul>');
             $('.aboutHoverBtn').hover(function(){
             $(this).focus();
             }).popover({
             content: popoverHtml,
             html: true,
             placement: 'bottom',
             container: 'nav',
             trigger: 'focus',
             delay: {
             show: 0,
             hide: 250
             }
             });*/
        },

        updateToggleMenu: function(open,content) {

            // Check if we are just passing content, in which case do it silently
            if(typeof open == 'string' || typeof open == 'object') {
                content = open;
                open = null;
            }

            // Fire a global app event for open/close status
            if(open) {
                $('body').trigger('mainnavopen');
            } else {
                $('body').trigger('mainnavclose');
            }

            if($('#mainMenu').length > 0) {

                // Reset visible elements
                $('#mainMenu .actionContent').show(); //, #mainMenu .daysPromo').show();
                $('#actionMenu').removeClass('hasMessage largeSuccess');

                // Set the template content
                if(content) {
                    $('#mainMenu .actionContent').html(content);
                }
                
                // Trigger the opening animation
                if(open !== null) {
                    if(open == false) {
                    	$('.navbarWrap').removeClass('move');
                    	$('.navbar .navbar-header').removeClass('hideNav');
                        $('#mainMenu').addClass('closing');
                        $('#mainMenu').removeClass('open');
                        $('.daysToRegister').removeClass('mobileNavVisible');
                        $('.hamburger-to-close').removeClass('changeclose');
                        $('.dashboardToggle').removeClass('open');
                        $('.singlePageWrap').removeClass('pushContentLeft pushContentRight');
                        $('body').removeClass('locked');
                        util.lockScrolling(false, '#sideMenu .menuWrap');
                        setTimeout($.proxy(function(){
                            $('#mainMenu').hide();
                            $('#mainMenu').removeClass('closing');
                            if(this.config.mode == 'full') {
                                window.location.hash = '';
                            }
                        //},this),($(window).width() >= 768 ? 2000 : 0));
                        },this),30);
                    } else {
                    	
                    	
                        $('#mainMenu').show();
                        $('body').addClass('locked');
                        $('.dashboardToggle').addClass('open');
                        $('.daysToRegister').addClass('mobileNavVisible');
                        $('.hamburger-to-close').addClass('changeclose');
                        $('.navbarWrap').addClass('move');
                        $('.navbar .navbar-header').addClass('hideNav');
                        util.lockScrolling(true, '#sideMenu .menuWrap');
                        setTimeout($.proxy(function(){
                            $('#mainMenu').addClass('open');
                            if(this.config.pushPageContent && this.config.pushPageContent == 'left') {
                                $('.singlePageWrap').addClass('pushContentLeft');
                            } else if(this.config.pushPageContent && this.config.pushContent == 'right') {
                                $('.singlePageWrap').addClass('pushContentRight');
                            }
                        //},this),($(window).width() >= 768 ? 250 : 0));
                        },this),30);
                    }
                }

            } else {
                // TODO - Refactor this into the modal methods
                $('body').append($(templates.actionmodal({
                    content: content
                })).modal());
            }

            // Pause any videos that are currently playing
            $('video').each(function(key, videoEl) {
                $(videoEl).get(0).pause();
            });

            forms.refreshFormHandlers();

        },

        initMenuHover: function(data) {
            // Add tooltip dropdown support to the menu
            var topMenuHtml = $('<ul></ul>');
            // Add the top title that opens the mainmenu
            topMenuHtml.append($('<h5>Menu Dashboard</h5>').click($.proxy(function(e){
                this.updateToggleMenu(true,false);
            },this)));
            $.each(data,function(index,topLink){
                if(topLink.hovermenu) {
                    topMenuHtml.append($('<li><a href="'+topLink.link+'">'+topLink.text+'</a></li>'));
                }
            });
            $('.dashboardToggle > a').hover(function(){
                $(this).focus();
                $(this).mouseleave(function(e){
                    var target =  e.toElement || e.relatedTarget;
                    if(!$(target).hasClass('popover') && $(target).parents('.popover').length == 0
                        && !$(target).hasClass('navbar')) {
                        $('#mainNavbar .popover').fadeOut();
                    }
                });
                $('#mainNavbar').find('.popover').mouseleave(function(){
                    $('#mainNavbar .popover').fadeOut();
                });
            }).popover({
                content: topMenuHtml[0],
                html: true,
                placement: 'bottom',
                container: 'nav',
                trigger: 'focus',
                delay: {
                    show: 0,
                    hide: 500
                }
            });

        },

        routeDashboardLinks: function() {

            if(this.config.mode != 'full') {
                return; // Only used on full main main (not used when modal forms are enabled)
            }

            this.updateToggleMenu(true, templates.dashboardlinks());
            $('#actionMenu').toggleClass('dashboardLinksShown', true);
            // If the user is logged in, update the days left to submit and logout link
            $('#mainNavbar a[href*="login"]').text('Logout').attr('href', '/logout.aspx');
            if (config.daysLeftSubmit >= 0) {
                $('#mainNavbar a[href*="register"]').html('<span class="dayLeft"></span> Days to Submit').attr('href', '#');
            }
        },

        routeRegister: function() {

            if(this.config.mode != 'full') {
                return; // Only used on full main main (not used when modal forms are enabled)
            }

            var userStatus = $.cookie('usr-reg');
            if (userStatus == 2) {
                this.updateToggleMenu(true);
                return;
            }

            $('#actionMenu').toggleClass('loginShown hasMessage largeSuccess', false);
            $('#sideMenu').addClass('mobAway');

            if (config.rampitRegTimeLeft < 1) {
                this.updateToggleMenu(true, templates.validationmessage({
                    message: (config.regConfig ? (config.regConfig.closeMessage || '') : ''),
                    messageTitle: (config.regConfig ? (config.regConfig.closeTitle || '') : ''),
                }));
                $('#actionMenu').toggleClass('regClosed', true);
            } else {
                this.updateToggleMenu(true, templates.register(config));
                $('#actionMenu').toggleClass('registerShown', true);
            }
        },

        routeLogin: function() {

            if(this.config.mode != 'full') {
                return; // Only used on full main main (not used when modal forms are enabled)
            }

            var userStatus = $.cookie('usr-reg');
            if (userStatus == 2) {
                this.updateToggleMenu(true);
                return;
            }

            $('#actionMenu').toggleClass('registerShown hasMessage largeSuccess', false);
            $('#actionMenu').toggleClass('loginShown', true);
            $('#sideMenu').addClass('mobAway');

            if (config.regConfig.type == 'expired') {
                this.updateToggleMenu(true, templates.validationmessage({
                    message: 'Login Expired'
                }));
            } else if (util.getParameterByName('token').length) {
                this.updateToggleMenu(true, templates.resetpassword()); // TODO - Update this to use the module form
            } else {
                this.updateToggleMenu(true, templates.login(config));
            }
        },

        routeForgot: function() {

            if(this.config.mode != 'full') {
                return; // Only used on full main main (not used when modal forms are enabled)
            }

            var userStatus = $.cookie('usr-reg');
            if (userStatus == 2) {
                this.updateToggleMenu(true);
                return;
            }

            $('#sideMenu').addClass('mobAway');
            this.updateToggleMenu(true, templates.forgotpassword());
        }
    }
});

require(['moment','app/user'],function(moment,user) {

    /*
     * Handlebars Helpers
     */

    Handlebars.registerHelper("prev", function (avalues, index, field, options) {
        if (index < 1) {
            return null;
        } else {
            return avalues[index - 1][field];
        }
    });

    Handlebars.registerHelper("math", function (lvalue, operator, rvalue, options) {
        lvalue = parseFloat(lvalue);
        rvalue = parseFloat(rvalue);

        return {
            "+": lvalue + rvalue,
            "-": lvalue - rvalue,
            "*": lvalue * rvalue,
            "/": lvalue / rvalue,
            "%": lvalue % rvalue
        }[operator];
    });

    Handlebars.registerHelper("tickLeft", function (lvalue, rvalue, options) {
        lvalue = parseFloat(lvalue);
        rvalue = parseFloat(rvalue);
        return (lvalue / (rvalue - 1)) * 100 + '%';
    });
    Handlebars.registerHelper("minusOne", function (value) {
        return value - 1;
    });
    Handlebars.registerHelper('ifCond', function (v1, v2, options) {
        if (v1 === v2) {
            return options.fn(this);
        }
        return options.inverse(this);
    });
    Handlebars.registerHelper("rowOpen", function (index_count, mod, block) {
        if (parseInt(index_count) % (mod) === 0) {
            return block.fn(this);
        }
    });
    Handlebars.registerHelper("rowClose", function (index_count, mod, block) {
        if (parseInt(index_count + 1) % (mod) === 0) {
            return block.fn(this);
        }
    });

    //  usage: {{dateFormat creation_date format="MMMM YYYY"}}
    Handlebars.registerHelper('dateFormat', function (context, block) {
        var f = block.hash.format || "MMM Do, YYYY";
        return new moment.utc(new Date(context)).format(f);
    });
    
     Handlebars.registerHelper ('truncate', function (str, len) {
        if (str.length > len) {
            var new_str = str.substr (0, len+1);

            while (new_str.length) {
                var ch = new_str.substr ( -1 );
                new_str = new_str.substr ( 0, -1 );

                if (ch == ' ') {
                    break;
                }
            }

            if ( new_str == '' ) {
                new_str = str.substr ( 0, len );
            }

            return new Handlebars.SafeString ( new_str); 
        }
        return str;
    });

    Handlebars.registerHelper('if_even', function(conditional, options) {
        if((conditional % 2) == 0) {
            return options.fn(this);
        } else {
            return options.inverse(this);
        }
    });

    Handlebars.registerHelper('checkUser', function (role,block) {
        if(user.checkRole(role)) {
            return block.fn(this);
        } else {
            return block.inverse(this);
        }
    });
    
    Handlebars.registerHelper("desktop_width", function(block) {
        if (window.innerWidth >= 769) {
            return block.fn(this);
        }
    });
    
    Handlebars.registerHelper("phone_width", function(block) {
        if (window.innerWidth <= 768) {
            return block.fn(this);
        }
    });
    
    Handlebars.registerHelper("xs_width", function(block) {
        if (window.innerWidth <= 480) {
            return block.fn(this);
        }
    });
    
    //mit finalists

    
    Handlebars.registerHelper("desktop_xl_width", function(block) {
        if (window.innerWidth >= 1200) {
            return block.fn(this);
        }
    });
    
    Handlebars.registerHelper("tablet_width", function(block) {
        if (window.innerWidth <= 1199) {
            return block.fn(this);
        }
    });
    
    
    

});
define("handlebars-helpers", function(){});


define('modules/forms/forgotpassword/js/base.js',["app/config","jquery","app/util","templates","app/forms","app/features/modal"], function(config,$,util,templates,forms,modal) {
    return {
        name: 'forgotpassword',
        selectors: false,
        remoteData: [],
        routes: [{
            route: 'forgot',
            fn: 'showForgot',
            silent: true,
            modal: true
        }],

        initialize: function () { },

        showForgot: function() {
            modal.refreshContent(templates.forgotpassword(),true);
            forms.refreshFormHandlers();
        }
    }
});

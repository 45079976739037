define('modules/forms/loginwic/js/base.js',["app/config","jquery","app/util","templates","app/forms","app/features/modal"], function(config,$,util,templates,forms,modal) {
    return {
        name: 'login',
        selectors: false,
        remoteData: [],
        routes: [{
            route: 'login',
            fn: 'showLogin',
            silent: true,
            modal: true
        }],

        initialize: function() { },

        showLogin: function () {
            if (config.regConfig.type == 'expired') {
                modal.refreshContent(templates.validationmessage({
                    message: 'Login Expired'
                }),true);
            } else if (util.getParameterByName('token').length) {
                modal.refreshContent(templates.resetpassword(),true);
            } else {
                modal.refreshContent(templates.login(config.regConfig),true);
            }
            forms.refreshFormHandlers();
        }
    }
});

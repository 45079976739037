define('modules/sidebar/js/base.js',["app/config","jquery","app/util","templates"], function(config,$,util,templates) {
    return {
        name: 'sidebar',
        selectors: '#sidebar',
        remoteData: [config.urls.sidebar],

        initialize: function (data) {

            // Load up the template
            $('#sidebar').append(templates.sidebar({
                menuItems: $.grep(data,function(rec){
                    return rec.megamenu; // Only include items set to display in megamenu
                })
            }));

            // User clicks a link in the sidebar
            $('.sidebar-item').click(function(event) {
                // If the sidebar has a tier1 menu...
                if ( $('.sidebar-tier1', $(this) ).length ) {
                    // Disable href action
                    event.preventDefault();
                    // Toggle the item's 'selected' class
                    $(this).toggleClass('sidebar-item-selected');
                }
				$('.daysToRegister a.navreg').show();
				$('.daysToRegister a.modal-close').hide();
            });

            // Sidebar inner (nested) menus
            $('#sidebar ul').click(function(event){
                event.stopPropagation(); // Stop propagation on inner links
            });

            $('.sidebar-inner-back').click(function(event) {
                setTimeout($.proxy(function(){
                    $(this).parent('.sidebar-inner-list').addClass('inner-closed');
                    $(this).parents('.sidebar-item-selected').height('auto');
                },this),1000);
                $(this).parents('.sidebar-item').css('right',parseInt($(this).closest('.sidebar-item')[0].style.right || 0, 10)-100+'%');
                event.stopPropagation();
            });
            $('.sidebar-inner-link').click(function(event) {
                $(this).children('.sidebar-inner-list').removeClass('inner-closed');
                $(this).parents('.sidebar-item').css('right',parseInt($(this).closest('.sidebar-item')[0].style.right || 0, 10)+100+'%');
                $(this).parents('.sidebar-item-selected').height($(this).children('.sidebar-inner-list').height());
                event.stopPropagation();
            });

            config.refresh();

        }
    }
});

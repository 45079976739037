define('modules/pagenav/sidenav/js/base.js',["app/config","jquery","app/util","templates","waypoints"], function(config,$,util,templates) {
    return {
        name: 'sidenav',
        selectors: '.sideNavWrap',
        remoteData: [],
        routes: [{
            // TODO - This potentially handles the refreshing of Waypoints, where we no longer need the "pagenavupdate" event
            route: '(news|events|judges)', // TODO - Ensure/define our routes better
            fn: function(){
                Waypoint.refreshAll();
            },
            extra: true,
            delay: 3500
        }],

        inviews: [],
		
        initialize: function (data) {

            // Render the template
            $('.sideNavWrap:visible').append(templates.sidenav());
            
            if ($('.sideNavWrap').hasClass('judgesSideNav')) {
                $('.sideNavWrap.judgesSideNav .sideNav').addClass('judges');
            }

            // Wait until nav element sections have been rendered using custom global event
            $('body').on('pagenavupdate', $.proxy(function(){

                // Clear existing template, remove waypoints
                $('.sideNavWrap').empty();
                this.disableWaypoints();

                // Render the new template
                $('.sideNavWrap:visible').append(templates.sidenav());
                
                if ($('.sideNavWrap').hasClass('judgesSideNav')) {
                	$('.sideNavWrap.judgesSideNav .sideNav').addClass('judges');
                }

                // Set the total count
                $('.sideNav .totalCount').html($('*[data-pagenav="true"]:visible').length);

                // Enable waypoints
                this.enableWaypoints();

                // Click handlers
                $('.sideNav .next, .sideNav .prev').on('click', $.proxy(function(e){
                    e.preventDefault();
                    this.disableWaypoints();
                    setTimeout($.proxy(function(){
                        if ($(e.currentTarget).hasClass('next') && ( $('.sideNav .currentCount').html() == $('.sideNav .totalCount').html())) {
                            util.scrollElToTop($('*[data-pagenav="true"]:visible').eq(0), -155); // Going from the last item to the first
                        } else if ($(e.currentTarget).hasClass('prev') && $('.sideNav .currentCount').html() == 1) {
                            console.log('last')
                            util.scrollElToTop($('*[data-pagenav="true"]:visible').last(),-150); // Going from the first item to the last
                        } else if($(e.currentTarget).hasClass('next')) {
                            util.scrollElToTop($('*[data-pagenav="true"]:visible').eq($('.sideNav .currentCount').html()),-150); // Moving to the next item
                        } else {
                            util.scrollElToTop($('*[data-pagenav="true"]:visible').eq($('.sideNav .currentCount').html()-2),-152); // Moving to the last item
                        }
                        setTimeout($.proxy(this.enableWaypoints,this),500);
                    },this),100);
                },this));
            },this));
            
            function updateFooter() {   
				//$('.sideNav').css('top', window.innerHeight + window.scrollY - 60 + "px");
				$('.sideNav').css('bottom', '8px');
			}
            
            var w = $( window ).width();
            
            if (w <=767){
            	$(document).bind('touchstart', function(e) {
				  if(!$(e.target).parents(".sideNav").length > 0) { // to avoid hiding the footer if it is node 'touched' by the user
					$('.sideNav').hide();
				  };
				});

				$(document).bind('touchend', function() {
				  //_.defer(function() { // handles waiting until snapback scrolling on mobile devices has finished
					$('.sideNav').show();          
				 // });
				});

				$(document).bind('scroll', function(e) {
				  updateFooter();
				});
            }

        },
        

        enableWaypoints: function() {
            // Add the waypoint listener
            $('*[data-pagenav="true"]:visible').each($.proxy(function(index,element){
                var inview = new Waypoint({
                    element: $(element),
                    offset: 150,
                    continuous: false,
                    handler: $.proxy(function(direction) {
                        $('.sideNav .currentCount').html($(element).index() + 1);
                    },this)
                });
                this.inviews.push(inview);
            },this));
            // Refresh all waypoints
            Waypoint.refreshAll();
        },
        
        

        disableWaypoints: function() {
            $.each(this.inviews,function(index,item){
                item.destroy();
            });
            this.inviews = [];
        }

    }
});

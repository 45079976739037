define('app/app',[

    // Compiled as part of the build process
    'templates',
    'components',

    // Custom App Config
    'app/config',
    'app/util',
    'app/contentloader',
    'app/forms', // TODO - We might end up refactoring this
    'app/router',
    'app/user',

    // App Features
    'app/features/modal',
    'app/features/video',
    'app/features/expandergrid',

    // Libs referenced in app initialization
    'jquery',

    // Global Libraries (in transition state)
    'bootstrap/transition',
    'bootstrap/carousel',
    'bootstrap/slider',
    'bootstrap/modal',
    'bootstrapValidator',

    'jquery-ui',
    //'jquery-ui-1.10.4.custom',
    'jquery.mobile.custom', // Touch events only - custom build
    'jquery.kinetic',
    'jquery.ba-throttle-debounce.min',
    'jquery.cookie',
    'jquery.mousewheel.min',
    'jquery.smoothdivscroll-1.3-min',

    'smartresize',
    'waypoints',
    'modernizr'

],function(Templates,components,config,util,contentloader,forms,router,user,modalFeature,videoFeature,expanderGridFeature,$){

    // Return app init methods
    return {

        // Initializes the app itself
        init: function() {

            var thisApp = this;

            // Run a short interval to detect back button scroll position
            // TODO - Relocate this
            var backInterval = setInterval(function(){
                if(!window.backScrollAmount && $(window).scrollTop() && $(window).scrollTop() > 0) {
                    window.backScrollAmount = $(window).scrollTop();
                }
            },10);
            setTimeout(function(){
                clearInterval(backInterval);
            },1000);

            (function() {

                this.init = function() {

                    // Load templates against class
                    this.templates = (window.Templates || {});

                    // Run some custom app util methods, usually specific to a build
                    // TODO - Eventually move this into build JS "options"
                    util.isSafari7();
                    util.bootstrapNumberCarousel('#home-page-carousel');
                    util.animateCarousel();

                    // Initialize the components
                    thisApp.initComponents();

                    // Initialize various classes
                    contentloader.init();
                    forms.initialize();
                    router.init();
                    user.init();

                    // App Features
                    // TODO - Make these self-initializing
                    modalFeature.init();
                    videoFeature.init();
                    expanderGridFeature.init();

                    // Start routing and trigger window resize for skrollr
                    $(window).hashchange().trigger('resize'); // TODO - Relocate this
                };

                this.init();

            }).call({});

        },

        buildConfig: null, // App build config from manifest

        // Initializes all necessary components based on selectors
        initComponents: function() {

            var initComponent = function(component) {
                // Attempt to load data if its selector exists, or if we explicity set no selector
                if(component.selectors === false || $(component.selectors).length > 0) {
                    // If data source has been set
                    if(component.remoteData && component.remoteData.length > 0) {
                        // TODO -- Wrap the following in a loop in order to load from multiple sources
                        $.getJSON(
                            component.remoteData[0], // Path
                            $.proxy(component.initialize,component) // Callback
                        );
                    } else {
                        component.initialize();
                    }
                    // If a router method has been set
                    if(component.routes && component.routes.length > 0) {
                        router.addRoutes(component.routes,component);
                    }
                }

            };

            if(components.build) {
                this.buildConfig = components.build;
            }

            components.load(initComponent);
        }

    }
});

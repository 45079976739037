define('app/features/expandergrid',['jquery','app/util','jquery.livequery'],function($,util){
    return {
        feature: true,

        config: {
            expanderSelector: '.learnSection .expanderGrid'
        },
        
        init: function() {
        
        	var gridCount;
            var gridCountIndex;
        
        	this.getActiveColumn();
            this.expanderGrid();
            
        },
        
        
        getActiveColumn: function() {
            return $('.learnSection .finalist-visible .inner-col[data-active="true"]').first();
        },
        
        expanderGrid: function() {
        
        
        	// set the first item to active
        	$('.learnSection .finalist-visible .inner-col').first().attr('data-active',true);
        	
        	
        	// vars
        	var expanderSelector = $('.learnSection .expanderGrid');	
        	var w = $( window ).width();
        	
        	// expandernav only shows when item is clicked
        	$('.expanderNav').hide();
        	
        	
        	// hide any inner-cols that are empty
        	$(expanderSelector).find('.inner-col').each(function(){
        		if ( ( !$.trim( $('h3', this).html() ).length ) || ( !$.trim( $('.hidden.innerSwap', this).html() ).length ) ) {
        			$(this).parent('.col-md-3').remove();
        		}
        	});
        	
        	if (  ( !$.trim( $('.row-wrap.expanderGrid.finalists .row').html() ).length )) {
        		$(this).remove();
        	}
        	
        	// TODO not sure hovering is needed
 			// Set up learn section animations and hover handling
            /*$(expanderSelector).find('.inner-col').hover(function(e){
            
            	$('.activeBox').show();
            	
                $(expanderSelector).find('.activeBox').css({
                    top: $(this).offset().top-$(expanderSelector).offset().top,
                    left: $(this).offset().left-15-$(expanderSelector).offset().left
                });
                
                $(expanderSelector).find('.activeBox').html($(this).html());
                
            }, $.proxy(function(e){
                var activeColumn = this.getActiveColumn();
                if($('.learnSection .detailRow:visible').length > 0) {
                	
                    $('.activeBox').css({
                        top: $(activeColumn).offset().top-$(expanderSelector).offset().top,
                        left: $(activeColumn).offset().left-15-$(expanderSelector).offset().left
                    });
                    $('.activeBox').html($(activeColumn).html()).css('opacity',1);
                }
            },this));*/
            
            
            

            
		
			function getGridCount() {
				gridCount = 0;
				//console.log('getGridCount');
				$('.learnSection .finalist-visible .inner-col').each(function(e){
					gridCount++;
			
				});
				gridCountIndex = gridCount;
			};
		
			
			$(document).on('tap','.learnSection .expanderGrid .finalist-visible .inner-col', function() {
				
				if($(window).width() >= 768) {
					$(this).find('.learn-more').trigger('tap');
				}
				else {
					
				}
			});
			
			$(document).on('tap','.learnSection .expanderGrid .finalist-visible .inner-col .learn-more', function(e) {
			
				e.stopPropagation();
				getGridCount();
			
				var innerCol = $(this).parents('.finalist-visible').find('.inner-col');
			
			
				$('.expanderNav').fadeIn();
		
				$('iframe').remove();
				$('.fullwindowvid').remove();

				// Set the active attribute on clicked column
				$('.learnSection .finalist-visible .inner-col').attr('data-active',false);
		
				$('.learnSection .icon-icon-arrow-right-block').not($('.learnSection .icon-icon-arrow-right-block.next')).remove();
				$(innerCol).attr('data-active',true).append('<i class="icon-icon-arrow-right-block"></i>');
				
			
		
				var activeIndex = $(innerCol).attr('data-id');
		
				// TODO possibly use the data id for active index
				$('.expanderNav .current-count').html(activeIndex);
		
		
				//dynamically load iframe if id is in video div
				var vidcontain = $(innerCol).find('.innerSwap .project-video');
				var vidid = $(innerCol).find('.innerSwap .project-video .video-id').text().replace(/\s/g, '');
			
			
				var vidsource = 'https://www.youtube.com/embed/'+vidid+'?rel=0';
		
				// if there's no id
				if (vidid == '') {
					$(innerCol).find('.innerSwap .corner-ribbon').detach().appendTo($(innerCol).find('.innerSwap .project-description .inner')).addClass('no-vid');
					$(innerCol).find('.innerSwap .project-video').addClass('no-vid');
				
				}
				else {
					$('<iframe/>', { width: "100%", height: "315", src: vidsource, frameborder: "0" }).appendTo(vidcontain);
					if (w >= 769) {
						$('<a href="https://www.youtube.com/embed/'+vidid+'?autoplay=0&amp;modestbranding=1&amp;rel=0&amp;hl=en" class="fullwindowvid" target="_blank">View in new full window</a>').appendTo(vidcontain);
					}
				}

				// Update and move description row
				var parentRow = $(this).parents('.row').first();
			
				$('.learnSection .detailRow .expanderContent').html($(innerCol).find('.innerSwap').html());
			
			
				$('.learnSection .detailRow').insertAfter(parentRow).hide();
			
				$('.learnSection .detailRow').fadeIn({
					complete: $.proxy(function() {
						$(innerCol).trigger('mouseenter'); // Trigger hover for repositioning as DOM moves
						
						setTimeout(function(){
							
							if($(window).width() >= 768) {
								$('.scrollable').stop().animate({
									scrollTop: $('.detailRow').position().top + 320
								}, 300);
							}
							else {
								$('html, body').stop().animate({
									scrollTop: $('.inner-col[data-active="true"]').offset().top + 600
								}, 300);
							}
						
						}, 500);
					
					},this)
				},300);
				
				
				
		
			});
			
			
            
            //set counts on expanderNav
			$('.expanderNav .total-count').html($('.finalistsSection *[data-pagenav="true"]').length);
			

            // ExpanderNav handlers
            $(document).on('tap', '.expanderNav i', $.proxy(function(e){
            
            	e.stopPropagation();
            	e.preventDefault();
            	
                var activeIndex = $('.learnSection .finalist-visible .inner-col[data-active="true"]').attr('data-id');
                //console.log(activeIndex);
                //console.log(gridCountIndex);
                
				if($(e.target).hasClass('next')) {
					
					
					if (activeIndex == gridCountIndex) {
						activeIndex = 1;
						
						$('.learnSection .finalist-visible .inner-col[data-id="'+(parseInt(activeIndex))+'"] .learn-more').trigger('tap');
						$('.expanderNav .current-count').html(parseInt(activeIndex));
					}
					
					else {
						$('.learnSection .finalist-visible .inner-col[data-id="'+(parseInt(activeIndex)+1)+'"] .learn-more').trigger('tap');
						$('.expanderNav .current-count').html(parseInt(activeIndex)+1);
					}
					
					
					
				}
				else if($(e.target).hasClass('prev')) {
				
					if (activeIndex == 1) {
						activeIndex = gridCountIndex;
						
						$('.learnSection .finalist-visible .inner-col[data-id="'+(parseInt(activeIndex))+'"] .learn-more').trigger('tap');
						$('.expanderNav .current-count').html(parseInt(activeIndex));
					}
					
					else {
						$('.learnSection .finalist-visible .inner-col[data-id="'+(parseInt(activeIndex)-1)+'"] .learn-more').trigger('tap');
						$('.expanderNav .current-count').html(parseInt(activeIndex)-1);
					}
					
					console.log(activeIndex)
					
					
				}
                
            },this));
            
            // close expander
            $(document).on('tap', '.expanderNav i.close', $.proxy(function(e){
            	$('.expanderNav').fadeOut();
            	$('.learnSection .detailRow .expanderContent').html('');
            	// TODO arrow border
            	$('.expanderNav .icon-icon-arrow-right-block').not($('.expanderNav .icon-icon-arrow-right-block.next')).remove();
            	
            	$('.detailRow').fadeOut(300);
            	
            	$('.learnSection .finalist-visible .inner-col').removeAttr('data-active');
            	
            		
            	
            },this));
            
            
           	// Handle finalists hashtag
            if(window.location.hash) {
                // Fetch the selected finalist item
                var finalistItem = $('.finalistsSection .col-md-3[data-finalist="' + location.hash.substr(1) + '"]');
                if (finalistItem) {
					
                    var innerCol = finalistItem.find('.inner-col');
                    //$(innerCol).trigger('click');
                    $(innerCol).trigger('tap');
                  	$(innerCol).trigger('mouseenter');
					//$(innerCol).trigger('hover');
					//$(innerCol).trigger('mouseover');
					$('.activeBox').css('opacity',1);
                   
                }
            }
            
        }
    }
});

define('app/features/video',['jquery','app/util','jquery.livequery'],function($,util){
    return {
        feature: true,

        config: {
            videoPlayerSelector: 'div.video'
        },

        init: function() {
            this.initYoutubeEmbeds();
            this.initCustomPlayer();
        },

        initYoutubeEmbeds: function() {
            $('*[data-youtube]').livequery(function(){
                var iframeEl = $('<iframe src="//www.youtube.com/embed/'+$(this).attr('data-youtube')+'?controls=2" style="width:100%;height:100%;"></iframe>');
                $(this).append(iframeEl);
            });
        },

        initCustomPlayer: function() {
            $(this.config.videoPlayerSelector).livequery($.proxy(function(index,element){
                this.addCustomPlayerControls(element);
            },this));
        },

        addCustomPlayerControls: function(element) {

            // Use modernizr class to remove either the fallback or the video
            if($('html').hasClass('video')) {
                $(element).find('.videoFallback').remove();
            } else {
                $(element).find('.videoSection').remove();
            }


            var video = $(element).find('video')[0];
            var duration;
            var minutes;
            var seconds;

            //if a video is found, get the duration
            if (video) {
                // get video duration
                var i = setInterval(function() {
                    if(video.readyState > 0) {
                    	duration = video.duration;
                        minutes = parseInt(video.duration / 60, 10);
                        seconds = video.duration % 60;

                        // (Put the minutes and seconds in the display)

                        clearInterval(i);
                    }
                }, 500);

                // Set up video listeners
                $(element).find('.videoText').hide();
				
                $(video).on("timeupdate", function() {
                    // Calculate the slider value
                    var value = (100 / this.duration) * this.currentTime;

                    //$('.introPage .video-controls .seekProgress').width(value + '%');

                    //generic scoping
                    $(this).parent('.video').find('.video-controls .seekProgress').width(value + '%');

                    $(this).parent('.video').find('.seekMeter input').val(value);

                    if(this.currentTime >= 0 && this.currentTime <= 5) {

                        //$(".introPage .videoText h2").html("Terry Garcia<br/><span>Chief Science and Exploration Officer</span>").fadeIn();
                    } else if (this.currentTime > 5 && this.currentTime <= 6) {
                        // $(".introPage .videoText h2").fadeOut();

                    } else if(this.currentTime > 12 && this.currentTime <= 43) {
                        //  $('.introPage .videoText h2').fadeOut();

                    } else if(this.currentTime > 43 && this.currentTime <= 48) {
                        //  $('.introPage .videoText h2').html('Applications Due<br/><span>April 14, 2015</span>').fadeIn();

                        // we are at the end of the video
                    } else if (this.currentTime >= duration) {
                        this.pause();

                        //load and show poster at the end of the video
                        this.load();
                        $(element).find('.video-controls').fadeOut();
                        $(element).find('.videoText').fadeIn();
                        $(element).find('.replay-overlay').fadeIn();
                        $(element).find('.videoText .vid-replay, .replay-overlay').click($.proxy(function(){
                            //this.load();
                            this.play();
                            return false;
                        },this));
                    }


                });

                $(element).find('.seekMeter input').change(function(){
                    video.currentTime = ($(this).val()/100)*video.duration;
                    return false;
                }).click(function(e){
                    e.stopImmediatePropagation();
                });

                // Handles play and pause elements
                //$([element,video,$('.togglePlay')[0],$('.play-overlay')[0]]).click(function(e){
                $([video,$('.togglePlay')[0],$('.play-overlay')[0]]).click(function(e){
                    e.preventDefault();
                    if(video.paused == false) {
                        video.pause();
                    } else if(video.currentTime >= 88.3) {
                        video.load();
                        video.play();
                    } else {
                        setTimeout(function(){
                            video.play();
                        },150);
                    }
                });


                // Updates the CSS for controls based on play/pause status
                $(element).find('.video-controls .play-icon').css('display', 'inline-block');
                $(element).find('.video-controls .pause-icon').css('display', 'none');
                $(video).on('pause', function() {
                    $(element).find('.video-controls .play-icon').css('display', 'inline-block');
                    $(element).find('.video-controls .pause-icon').css('display', 'none');
                });
                $(video).on('play', function() {
                    $(element).find('.replay-overlay').fadeOut();
                    $(element).find('.play-overlay').fadeOut();
                    $(element).find('.video-controls .pause-icon').css('display', 'inline-block');
                    $(element).find('.video-controls .play-icon').css('display', 'none');
                    $(element).find('.video-controls').show();
                    $(element).find('.videoText').hide();
                });

            }
            else {
                // else
            }

        }
    }
});

define('app/features/modal',["app/config","jquery","app/router"], function(config,$,router) {
    return {

        ready: false,
        feature: true,

        tpl: '<div class="actionWrap modal slideIn section layout-vertical-center layout-auto-height" data-bgimage="default-body"><div class="modal-close icon-icon-close modal-mobile"></div><div class="modalWrap"><div class="contentWrap"></div></div></div>',

        config: {
            //animation: 'slideLeftIn'
            animation: 'modalSlideAnimation'
        },

        init: function() {
            $('.modalActionWrap').append($(this.tpl)).modal({
                show: false,
                backdrop: true
            });
            if(this.config.animation) {
                $('.actionWrap.modal').addClass(this.config.animation);
            }
            this.addFormModalListeners();
            this.ready = true;
            
        },

        // Method that loads content into the modal
        // Second param defaults to true to immediately show the modal
        refreshContent: function(content,show) {
        	$('.form, .modal-container, .messageWrap, .contentModalWrap').removeClass('wic-modal-inner-animation'); // TODO - Make this project generic
            /*if(!this.ready) {
                this.init(); // TODO - Add back in self-initalization
            }*/

            if(show || show === undefined) {
                $('.actionWrap.modal').modal('show');
            }
            $('.actionWrap.modal .contentWrap').html(content);
            //$('.daysToRegister a.navreg').hide();
            $('.daysToRegister a.modal-close').show();
            $('.daysToRegister a.navreg').addClass('back-to-btn');
            $('.daysToRegister a.modal-close').addClass('back-to-btn');
			
			// this is specific to WIC
            setTimeout(function(){
            	$('.form, .modal-container, .messageWrap, .contentModalWrap').addClass('wic-modal-inner-animation'); // TODO - Make this project generic
     		},10);
        },

        // Closes the modal, with a delay if animation is used
        closeModal: function() {
        	$('.form, .modal-container').removeClass('wic-modal-inner-animation'); // TODO - Make this project generic
            if(this.config.animation) {
                $('.actionWrap.modal').removeClass('in');
                $('.toggle-screen').removeClass('in');
                $('.modal-container').removeClass('wic-modal-inner-animation');
                setTimeout(function(){
                    $('.actionWrap.modal').modal('hide');
                },1000);
            } else {
                $('.actionWrap.modal').modal('hide');
            }
            $('body').removeClass('locked')
            
        },

        addFormModalListeners: function() {

            // This happens *after* a modal is hidden
            $('.actionWrap.modal').on('hidden.bs.modal',function(){
                $('.pageContainer.active, .footerWrap').show(); // Show our last active page and footer
                $('body').scrollTop($('body').data('scroll')); // Scroll back to our previous point on the page
                //$('.daysToRegister a.navreg').show();
                $('.daysToRegister a.modal-close').hide();
                $('.daysToRegister a.navreg').removeClass('back-to-btn');
            	$('.daysToRegister a.modal-close').removeClass('back-to-btn');
            });

            // This happens *before* a modal is shown
            $('.actionWrap.modal').on('show.bs.modal',function() {
                $('body').data('scroll',$('body').scrollTop()); // Save our body scroll position
                $('.actionWrap.modal > .contentWrap').empty(); // Empty anything existing
                setTimeout(function(){
                	$('body').addClass('locked')
                	$('.toggle-screen').addClass('in');
                },500);
            });

            // This happens *after* a modal is shown
            $('.actionWrap.modal').on('shown.bs.modal',function(){
            	// TODO line below commented out works for WIC not MIT
                //$('.pageContainer.active, .footerWrap').hide();
                //$('.footerWrap').hide();
            });

            // Click handlers
            $('.actionWrap.modal .modal-close, .modal-close').livequery($.proxy(function(index,el){
                $(el).on('click', $.proxy(this.closeModal,this));
            },this));
            $('.actionWrap.modal').delegate('.modal-back','click',this.onBackArrow);
        },

        onBackArrow: function(e) {
            e.preventDefault();
            window.location.hash = router.routeHistory.pop();
        }
    }
});
